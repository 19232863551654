import React from 'react';
import { SectionConfigLayout } from '../../utils';
import BaseSectionConfig from '../../BaseSectionConfig';

class FreshSweetsBannerCollectionFormItem extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.onFormItemChange(event.target.name, event.target.value);
    }

    render() {
        const { img, alt, imgCaption, altCaption, actionLink  } = this.props;
        return (
            <form>
                <div className="form-group">
                    <label htmlFor="img">Banner Image URL</label>
                    <input type="text" className="form-control" id="img" name="img" value={img} onChange={this.handleChange} placeholder="Enter the banner image URL" />
                </div>
                <div className="form-group">
                    <label htmlFor="alt">Banner Image alt</label>
                    <input type="text" className="form-control" id="alt" name="alt" value={alt} onChange={this.handleChange} placeholder="Enter the banner image alt" />
                </div>
                <div className="form-group">
                    <label htmlFor="imgCaption">Banner Image Caption URL</label>
                    <input type="text" className="form-control" id="imgCaption" name="imgCaption" value={imgCaption} onChange={this.handleChange} placeholder="Enter the banner image caption URL" />
                </div>
                <div className="form-group">
                    <label htmlFor="altCaption">Banner Image Caption alt</label>
                    <input type="text" className="form-control" id="altCaption" name="altCaption" value={altCaption} onChange={this.handleChange} placeholder="Enter the banner image caption alt" />
                </div>
                <div className="form-group">
                    <label htmlFor="actionLink">Action Link</label>
                    <input type="text" className="form-control" id="actionLink" name="actionLink" value={actionLink} onChange={this.handleChange} placeholder="Enter the action link URL" />
                </div>
            </form>
        );
    }
}


class FreshSweetsBannerCollectionFormGeneral extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.onFormGeneralChange(event.target.name, event.target.value);
    }

    render() {
        const { items_count } = this.props;
        return (
            <form>
                <div className="form-group">
                    <label htmlFor="items_count">Number of banner items</label>
                    <input type="number" min="0" max="100" className="form-control" id="items_count" name="items_count" value={items_count} onChange={this.handleChange} placeholder="Enter number of banner items" />
                </div>
            </form>
        );
    }
}

class FreshSweetsBannerCollection extends BaseSectionConfig {
    defaultTitle = "Fresh sweets Banner Collection";
    scopeName = "FreshSweetsBannerCollection";

    constructor(props) {
        super(props);

        this.state = this.getStateFromLocalStorage() || {
            items: [
                { 
                    img: 'https://cdn11.bigcommerce.com/s-tyhv2hf1i4/product_images/uploaded_images/category-1.png', 
                    alt: 'category-1.png', 
                    imgCaption: 'https://cdn11.bigcommerce.com/s-tyhv2hf1i4/product_images/uploaded_images/category-1-abs.png',
                    altCaption: 'category-1-abs.png',
                    actionLink: '#'
                },
                { 
                    img: 'https://cdn11.bigcommerce.com/s-tyhv2hf1i4/product_images/uploaded_images/category-2.png', 
                    alt: 'category-2.png', 
                    imgCaption: 'https://cdn11.bigcommerce.com/s-tyhv2hf1i4/product_images/uploaded_images/category-2-abs.png',
                    altCaption: 'category-2-abs.png',
                    actionLink: '#'
                },
                { 
                    img: 'https://cdn11.bigcommerce.com/s-tyhv2hf1i4/product_images/uploaded_images/category-3.png', 
                    alt: 'category-3.png', 
                    imgCaption: 'https://cdn11.bigcommerce.com/s-tyhv2hf1i4/product_images/uploaded_images/category-3-abs.png',
                    altCaption: 'category-3-abs.png',
                    actionLink: '#'
                },
                { 
                    img: 'https://cdn11.bigcommerce.com/s-tyhv2hf1i4/product_images/uploaded_images/category-4.png', 
                    alt: 'category-4.png', 
                    imgCaption: 'https://cdn11.bigcommerce.com/s-tyhv2hf1i4/product_images/uploaded_images/category-4-abs.png',
                    altCaption: 'category-4-abs.png',
                    actionLink: '#'
                },
            ]
        };
    }

    render() {
        const data = Buffer.from(JSON.stringify(this.state)).toString('base64');
        return (
            <SectionConfigLayout elClass="FreshSweetsBannerCollection" title={this.title} sectionKey={this.sectionKey}
                 panels={[
                    {
                        title: "General",
                        element: <FreshSweetsBannerCollectionFormGeneral onFormGeneralChange={this.onFormGeneralChange} {...this.state} heading={this.state.heading} items_count={this.state.items.length} />,

                    },
                    ...this.state.items.map((item, index) => ({
                        title: `Banner item ${index + 1}`,
                        element: <FreshSweetsBannerCollectionFormItem onFormItemChange={this.onFormItemChange.bind(this, index)} {...item} />,
                    })),
                ]}


                code={`<div class="u-hiddenVisually" data-local-banner-position="fresh-sweets-banner-collection" data-template="fresh_sweets_banner_collection" data-params="${data}">DO NOT REMOVE THIS LINE</div>`}
            />
        );
    }
}

export default FreshSweetsBannerCollection;
